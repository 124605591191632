// @flow

import React from "react";

import Layout from "../layouts/layout";
import Lesson from "../components/Lesson";

type Props = {
  pageContext: {
    data: { number: number, title: string },
    text: string
  }
};

function LessonTemplate(props: Props) {
  return (
    <Layout title={props.pageContext.data.title}>
      <Lesson {...props.pageContext} />
    </Layout>
  );
}

export default LessonTemplate;

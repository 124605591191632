// @flow

import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import styled from "@emotion/styled";

import DownloadButton from "./DownloadButton";

const TitleStyled = styled("h1")`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 35px;
`;

type Props = {
  data: { number: number, title: string },
  text: string
};

function Lesson(props: Props) {
  const { data, text } = props;

  return (
    <section className="p-0 container">
      <div className="row">
        <main className="col-12 col-lg-9">
          <header>
            <strong>Lekcja {data.number}</strong>
            <TitleStyled>{data.title}</TitleStyled>
          </header>
          <MDXRenderer>{text}</MDXRenderer>
        </main>
        <aside className="col-12 col-lg-3 mt-5 mt-lg-0">
          <DownloadButton
            className="position-fixed"
            file={`/lekcja_nr_${data.number}.pdf`}
            text="Pobierz materiały"
          />
        </aside>
      </div>
    </section>
  );
}

export default Lesson;
